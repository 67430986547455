<div>
    <a routerLink="/dashboard/asn/list" mat-raised-button color="primary" class="btn">Find ASN</a>
    <div class="find-picklist">

        <form class="example-form" (ngSubmit)="search(f)" #f="ngForm">


            <table style="width: 100%;">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <mat-label>Search for ASN</mat-label>
                            <input matInput ngModel name="term" required />
                        </mat-form-field>
                    </td>
                    <td width="113">
                        <button mat-flat-button color="accent" [disabled]="!f.valid">
                            <mat-icon>search</mat-icon> SEARCH
                        </button>
                    </td>
                </tr>
            </table>


        </form>
        <p>OR</p>
        <a routerLink="/dashboard/asn/scan" mat-flat-button color="primary" class="btn">Scan ASN</a>
    </div>
</div>