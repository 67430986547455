<div class="page-wrap">
    <div class="heading result">
      <div class="head">
        <h1>TRANSFER POSTING</h1>
      </div>
      <section>
        <div class="wrap">
          <a routerLink="/dashboard/transfer-posting/bin-to-bin-transfer/{{this.clientId}}" mat-raised-button color="primary" class="btn"> Bin to Bin Transfer </a>
          <a routerLink="/dashboard/transfer-posting/bin-to-content-transfer/{{this.clientId}}" mat-raised-button color="primary" class="btn"> Bin to Content Transfer </a>
          
          </div>
      </section>
    </div>
  </div>
  
  
  
  