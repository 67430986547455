<div class="page-wrap">
    <div class="heading result">
        <div class="head">
            <h1>ASN SCAN BOX</h1>
            <mat-slide-toggle color="primary"  [(ngModel)]="hhtScanEnabled" (change)="hhtScanToggle($event)
            ">USE HHT Device</mat-slide-toggle>
        </div>
    </div>
    <div class="find-picklist">
        <form class="example-form" (ngSubmit)="search(f)" #f="ngForm">
            <table style="width: 100%;">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <mat-label>Search for ASN Scan-Box</mat-label>
                            <input matInput ngModel name="scanBox" required (keyup)="changeLPN($event)"
                            autocomplete="on"  />
                        </mat-form-field>
                    </td>
                    <td width="113">
                        <button mat-flat-button color="accent" [disabled]="!f.valid || hhtScanEnabled">
                            <mat-icon>search</mat-icon> SEARCH
                        </button>
                    </td>
                </tr>
            </table>
        </form>
    </div>
    <div class="heading" *ngIf="scanData?.length > 0">
            <div class="item" *ngFor="let item of scanData">
                <p>ASN No: <span>{{ item.ASN_no }} </span></p>
                <p>Invoice No: <span>{{ item.Custinv }}</span></p>
                <p>Box No: <span>{{ scanBox }} </span></p>
                <p>Total Box Qty: <span>{{ item.TotalBoxQty }} </span></p>
                <p>Total Box SKU: <span>{{ item.SKUCount }}</span></p>
                <p>Total Scan: <span>{{ item.ScanQTy }}</span></p>
                <p>Total Box: <span>{{ item.BoxCount }}</span></p>


            </div>
        
    </div>

    <div class="find-picklist" *ngIf="scanData?.length > 0">
        <table class="tbl-class">
            <tr>
               <th>SKU/EAN</th>
               <th>BOX QTY</th>
               <th>Receive QTY</th>
            </tr>
            <tr *ngFor="let items of scanTableData">
                <td>{{ items.SKU }}</td>
                <td>{{ items.TotalBoxQty }}</td>
                <td (click)="manualUpdate(items.SKU, items.ScanQTy, items.TotalBoxQty)">{{ items.ScanQTy }}</td>
            </tr>
        </table>
    </div>

    <mat-paginator *ngIf="scanData?.length > 0" [length]='numberOfRecords'
              [pageSize]="10"
              (page)="onPaginateChange($event)">
        </mat-paginator>
</div>