<div class="page-wrap">
    <div class="heading result">
        <div class="head">
            <h1>SKU WEIGHT UPDATION</h1>
            <mat-slide-toggle color="primary"  [(ngModel)]="hhtScanEnabled" (change)="hhtScanToggle($event)
            ">USE HHT Device</mat-slide-toggle>
        </div>
    </div>
    <div class="find-picklist">
        <form class="example-form" (ngSubmit)="search(f)" #f="ngForm">
            <table style="width: 100%;">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <mat-label>Search for EAN/SKU</mat-label>
                            <input matInput ngModel name="skuName" required (keyup)="changeLPN($event)"
                            autocomplete="off"  />
                        </mat-form-field>
                    </td>
                    <td width="113">
                        <button mat-flat-button color="accent" [disabled]="!f.valid || hhtScanEnabled">
                            <mat-icon>search</mat-icon> SEARCH
                        </button>
                    </td>
                </tr>
            </table>
        </form>
    </div>
    <div class="find-picklist"  *ngIf="valideSku">
        <form class="example-form" (ngSubmit)="updateSku(f1)" #f1="ngForm">
            <table style="width: 100%;">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <mat-label>Enter Sku Weight</mat-label>
                            <input matInput type="number" ngModel name="weight" required
                            autocomplete="off"  ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" />
                        </mat-form-field>
                    </td>
                    <td width="113">
                        <button mat-flat-button color="accent" [disabled]="!f1.valid">
                            <mat-icon>update</mat-icon> Update
                        </button>
                    </td>
                </tr>
            </table>
        </form>
    </div>
    

    

    
</div>