<div class="page-wrap">
  <div class="heading result">
    <div class="head">
      <h1>INVENTORY</h1>
    </div>
    <section>
      <div class="wrap">
        <a routerLink="/dashboard/inventory/cycle-count" mat-raised-button color="primary" class="btn"> CYCLE COUNT </a>
        <a routerLink="/dashboard/inventory/transfer-posting" mat-raised-button color="primary" class="btn">  TRANSFER POSTING </a>
        <a routerLink="/dashboard/inventory/bin-wise-stock" mat-raised-button color="primary" class="btn"> BIN WISE STOCK </a>
        <a routerLink="/dashboard/inventory/hu-content-search" mat-raised-button color="primary" class="btn"> HU CONTENT SEARCH </a>
        </div>
    </section>
  </div>
</div>



