<div class="page-wrap">
    
    <div class="heading result">
        <div class="head">
            <h1>HU CONTENT SEARCH</h1>
            <mat-slide-toggle
            color="primary"
            [(ngModel)]="hhtScanEnabled"
            (change)="hhtScanToggle($event)"
            >USE HHT Device</mat-slide-toggle
          >
          </div>
        <div><br>
            <p *ngIf="header.PartyName"> Party Name: <span>{{header.PartyName}} </span></p>
            <div class="item" *ngIf="header.PartyName">
                <p>REF NO: <span>{{header.REF_nO }} </span></p>
                <p>BOX QTY: <span>{{header.BOXQTY }} </span></p>
                <p>Total SKU: <span>{{header.Total_SKU}} </span></p>
                
            </div>
           
        </div>
       
    </div>
    <div class="find-picklist">
        <form class="example-form" (ngSubmit)="search(f)" #f="ngForm">
            <table style="width: 100%;">
                <tr>
                    <td>
                        <mat-form-field class="example-full-width">
                            <mat-label>Search Box</mat-label>
                            <input matInput ngModel name="box" required (keyup)="changeLPN($event)"
                            autocomplete="off"  />
                        </mat-form-field>
                    </td>
                    <td width="113">
                        <button mat-flat-button color="accent" [disabled]="!f.valid || hhtScanEnabled">
                            <mat-icon>search</mat-icon> SEARCH
                        </button>
                    </td>
                </tr>
            </table>
        </form>
    </div>
     <div class="find-picklist" *ngIf="table?.length > 0"> 
        <table class="tbl-class">
            <tr>
               <th>SKU</th>
               <th>QTY</th>
            </tr>
            <tr *ngFor="let items of table">
                <td>{{ items.SKU }}</td>
                <td>{{ items.QTY }}</td>
            </tr> 
        </table>
    </div> 
    <mat-paginator *ngIf="table?.length > 0" [length]='numberOfRecords'
              [pageSize]="10"
              (page)="onPaginateChange($event)">
        </mat-paginator>
</div>