<div class="wrap">

<a routerLink="/dashboard/receiving" mat-raised-button color="primary" class="btn">
    <mat-icon>commute</mat-icon>
    RECEIVING </a>
    <a routerLink="/dashboard/main-asn" mat-raised-button color="primary" class="btn"><mat-icon>document_scanner</mat-icon> ASN </a>
<!-- <a routerLink="/dashboard/asn" mat-raised-button color="primary" class="btn"><mat-icon>document_scanner</mat-icon> ASN SCAN </a> -->
<!-- <a routerLink="/dashboard/return-gate-in" mat-raised-button color="primary" class="btn"><mat-icon>scanner</mat-icon>  ASN SCAN-BOX </a> -->
<a routerLink="/dashboard/put-away" mat-raised-button color="primary" class="btn"><mat-icon>settings_overscan</mat-icon> PUT AWAY </a>
<a routerLink="/dashboard/put-away-box" mat-raised-button color="primary" class="btn"><mat-icon>location_searching</mat-icon> PUT AWAY-BOX </a>
<a routerLink="/dashboard/dispatch" mat-raised-button color="primary" class="btn"><mat-icon>local_shipping</mat-icon> DISPATCH </a>

<a routerLink="/dashboard/pick-list" mat-raised-button color="primary" class="btn"><mat-icon>widgets</mat-icon> PICK LIST </a>
<a routerLink="/dashboard/box-pick-list" mat-raised-button color="primary" class="btn"><mat-icon>inventory_2</mat-icon> PICK LIST RETAIL </a>
<a routerLink="/dashboard/sku-weight-updation" mat-raised-button color="primary" class="btn"><mat-icon>update</mat-icon> SKU WEIGHT UPDATION </a>
<a routerLink="/dashboard/inventory" mat-raised-button color="primary" class="btn"><mat-icon>library_books</mat-icon> INVENTORY </a>



<a routerLink="/dashboard/scan-test" mat-raised-button color="warn" class="btn"><mat-icon>qr_code_scanner</mat-icon> SCAN TEST </a>
</div>