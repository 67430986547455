
<h2 mat-dialog-title>Manully update qty for SKU {{data.sku}}</h2>

<h3>Bulk update</h3>
<form class="example-form" (ngSubmit)="updateSku(f)" #f="ngForm">


  <table style="width: 100%;">
      <tr>
          <td>
              <mat-form-field class="example-full-width">
                  <mat-label>Update Quantity</mat-label>
                  <input type="number" matInput ngModel name="qty" required/>
              </mat-form-field>
          </td>
      </tr>
      <tr>
          <td>
              <button mat-flat-button color="accent" [disabled]="!f.valid">
                 Update
              </button>
          </td>
      </tr>
  </table>


</form>
<br/>
<br/>

<h3>Quick Update</h3>

<mat-dialog-actions align="center">
  <button mat-flat-button (click)="decrementByOne()" color="accent">-</button>
  <button mat-flat-button (click)="incrementByOne()" color="primary">+</button>
</mat-dialog-actions>
