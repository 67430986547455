<div class="item-heading">
    <p>WHLOCATION:</p>
    <p>BALQTY:</p>
</div> 
<div class="item" *ngFor="let items of btnBinData">
    <p><span>{{items.WHLOCATION }} </span></p>
    <p><span>{{items.BALQTY}} </span></p>
</div> 
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </div>
