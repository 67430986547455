<div class="page-wrap">
    <div class="heading result">
      <div class="head">
        <h1>ASN</h1>
      </div>
      <section>
        <div class="wrap">
          <a routerLink="/dashboard/asn" mat-raised-button color="primary" class="btn"> ASN SCAN </a>
          <a routerLink="/dashboard/return-gate-in" mat-raised-button color="primary" class="btn">ASN SCAN-BOX </a>
          <a routerLink="/dashboard/asn-scan-rfid" mat-raised-button color="primary" class="btn"> ASN SCAN-RFID </a>
          </div>
      </section>
    </div>
  </div>
  
  
  
  