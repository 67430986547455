<a routerLink="/dashboard/dispatch/open-mdn" mat-raised-button color="primary" class="btn">OPEN MDN</a>

<div class="find-asn">
<form class="example-form" (ngSubmit)="search(f)" #f="ngForm">


    <table style="width: 100%;">
        <tr>
            <td>
                <mat-form-field class="example-full-width">
                    <mat-label>Search for MDN</mat-label>
                    <input matInput ngModel name="mdn" required/>
                </mat-form-field>
            </td>
            <td width="113">
                <button mat-flat-button color="accent" [disabled]="!f.valid">
                    <mat-icon>search</mat-icon> SEARCH
                </button>
            </td>
        </tr>
    </table>


</form>
</div>